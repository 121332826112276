import { FormattedMessage } from "gatsby-plugin-react-intl"
import React from "react"
import { ItemLinkTile } from "../../../molecules/ItemLinkTile"

import * as S from "./style"

const BlogPageContent: React.FC = () => {
  return (
    <S.Main>
      <S.Wrapper>
        <S.Header>
          <FormattedMessage
            defaultMessage="Latest news in education system digitization, educational innovations."
            description="information"
          />
        </S.Header>
        <S.Divider />
        <S.Subheader>
          <FormattedMessage
            defaultMessage="New articles coming soon"
            description="information"
          />
        </S.Subheader>
      </S.Wrapper>
    </S.Main>
  )
}

export { BlogPageContent }
