import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

import { BlogPage } from "../components/sets/home/BlogPage"
import { SEO } from "../components/atoms/SEO"

const Blog: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <SEO
        subtitle={intl.formatMessage({
          defaultMessage: "Blog",
          description: "website subtitle",
        })}
      />
      <BlogPage />
    </>
  )
}

export default Blog
